$default-color-white: #ffffff;

$default-color-grey: #e6ebef;
$default-color-grey-50: #e6ebefa8;
$default-color-simple-grey: #707070;
$default-color-simple-grey-75: #707070bd;

$default-color-lighter-grey: #ced6dc;
$default-color-light-grey: #fafafa;
$default-color-boxshadow-grey: #b0b0b029;

$default-color-black: #292f33;

$default-color-green: #acdad7;
$default-color-light-green: #acdad747;

$default-color-shadow-primary: #acdad72b;
$default-color-shadow-secondary: #00000029;
$default-color-button-shadow: #002a283d;

$default-color-red: #ff527f;
$default-color-red-secondary: #e85a5a;
$default-color-red-error: #ff5858;
$default-color-red-error-50: #ff585878;

$default-color-warning: #eeff00;
$default-color-warning-50: #eeff0096;

$default-color-blue: #0a9ee8;
