@import "styles/variables";

.waitlist {
  margin-top: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 24px;
  z-index: 99999;

  &__title {
    font-weight: bold;
    font-size: 16px;

    @include laptop {
      font-size: 24px;
    }
  }

  &__input {
    display: flex;
    gap: 8px;

    &-text {
      width: -webkit-fill-available;
    }

    &-button {
      width: 200px;

      @media (max-width: 1000px) {
        width: -webkit-fill-available;
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &__send {
    font-weight: bold;
    font-size: 14px;

    @include laptop {
      font-size: 20px;
    }
  }
}
