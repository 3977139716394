$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1024px;
$breakpoint-small-desktop: 1280px;
$breakpoint-desktop: 1440px;

@mixin mobile() {
  @media (max-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin to-mobile() {
  @media (max-width: ($breakpoint-mobile - 1)) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin to-tablet() {
  @media (max-width: ($breakpoint-tablet - 1)) {
    @content;
  }
}

@mixin laptop() {
  @media (min-width: $breakpoint-laptop) {
    @content;
  }
}

@mixin to-laptop() {
  @media (max-width: ($breakpoint-laptop - 1)) {
    @content;
  }
}

@mixin small-desktop() {
  @media (min-width: $breakpoint-small-desktop) {
    @content;
  }
}

@mixin to-small-desktop() {
  @media (max-width: ($breakpoint-small-desktop - 1)) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}
