@import "styles/variables";

.notification {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-transform: initial;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font: normal 14px/16px $default-font-oswald;
    margin-bottom: 4px;

    &-seen {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $default-color-red-error;
      position: absolute;
      left: -14px;
    }
  }

  &--seen {
    color: $default-color-simple-grey;
  }

  &--admin {
    color: $default-color-red;
  }

  &__description {
    font: normal 12px/14px $default-font-montserrat;
    text-align: left;
  }
}
