@import "styles/variables";

.highlight {
  position: relative;
  display: flex;
  align-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 32px;
  justify-content: center;
  width: 90vw;
  padding: 0;

  @include laptop {
    width: 290px;
  }

  &__countdown {
    display: flex;
    justify-content: space-around;
    width: 100%;
    text-align: center;

    & div {
      font-size: 20px;
      line-height: 100%;
    }

    & span {
      font-size: 14px;
      line-height: 100%;

    }

    & > div {
      font-family: $default-font-montserrat;
      background: $default-color-green;
      padding: 16px 8px;
      border-radius: 16px;
      width: 75px;
      box-shadow: 0px 3px 15px $default-color-boxshadow-grey;
    }

    @include laptop {
      & div {
        font-size: 40px;
        line-height: 100%;
      }
  
      & span {
        font-size: 20px;
        line-height: 100%;
  
      }
  
      & > div {
        font-family: $default-font-montserrat;
        background: $default-color-green;
        padding: 8px 16px;
        border-radius: 16px;
        width: 120px;
        box-shadow: 0px 3px 15px $default-color-boxshadow-grey;
      }
    }
  }

  @include laptop {
    width: max-content;
    margin-bottom: 100px;
  }

  &--scroll {
    margin: 32px auto;
  }

  &__parallax {
    display: none;

    &--small {
      position: absolute;
      top: 0;
      left: 100%;
      zoom: 0.8;

      @include laptop {
        zoom: 1;
        top: 5%;
        left: 98%;
      }
    }

    &--medium {
      position: absolute;
      left: 80%;
      zoom: 0.6;
      top: 14%;

      @include laptop {
        zoom: 1;
        left: 96%;
        top: 23%;
      }
    }

    &--big {
      position: absolute;
      left: 0;
      zoom: 0.75;
      left: -35%;
      top: -5%;

      @include laptop {
        top: 20%;
        zoom: 1;
      }
    }
  }

  & > span {
    overflow: visible !important;
  }

  &__background {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__sneaker {
    z-index: 2;
    box-shadow: 0px 3px 20px $default-color-light-green;
    border-radius: 20px;
    background: $default-color-white;
    object-fit: contain;
    cursor: pointer;
    height: 180px;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 60px;
    font-weight: bold;
    justify-content: space-around;

    width: auto;
    height: auto;
    padding: 24px 12px;
    gap: 16px;


    &-title {
      @media (max-width: 1000px) { 
        font-size: 24px;
      }
    }

    @include laptop {
      height: 388px;
      width: 600px;
      border-radius: 36px;
    }
  }

  &__title {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 24px;
    color: $default-color-white;
    background: $default-color-black;
    font: bold 11px/12px $default-font-oswald;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    border-radius: 31px 0px 0px 31px;
    cursor: pointer;
    padding: 3px 5px 3px 10px;

    @include laptop {
      top: 52px;
      padding: 12px 32px;
      font-size: 13px;
      line-height: 17px;
      letter-spacing: 2.34px;
    }
  }

  &__description {
    font: bold 16px/18px $default-font-montserrat;
    text-align: center;
    z-index: 2;
    cursor: pointer;
    max-width: 290px;
    margin: 8px 0;

    @include laptop {
      max-width: 600px;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: 3.2px;
      margin: 16px 0 12px 0;
    }
  }
}
